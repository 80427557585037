import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 获取原型对象上的push函数
const originalPush = Router.prototype.push
// 修改原型对象中的push方法
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue'),
      meta: { title: '首页', keywords: '', description: '' }
    },
    {
      path: '/history',
      name: 'history',
      component: () => import('./views/History.vue'),
      meta: { title: '玛娅历程', keywords: '', description: '' }
    },
    {
      path: '/spaces',
      name: 'spaces',
      component: () => import('./views/Spaces.vue'),
      meta: { title: '玛娅空间', keywords: '', description: '' }
    },
    {
      path: '/space-house',
      name: 'space-house',
      component: () => import('./views/SpaceHouse.vue'),
      meta: { title: '玛娅空间-全屋定制', keywords: '', description: '' }
    },
    {
      path: '/space-cabinet',
      name: 'space-cabinet',
      component: () => import('./views/SpaceCabinet.vue'),
      meta: { title: '玛娅空间-橱柜系列', keywords: '', description: '' }
    },
    {
      path: '/space-closet',
      name: 'space-closet',
      component: () => import('./views/SpaceCloset.vue'),
      meta: { title: '玛娅空间-衣帽系列', keywords: '', description: '' }
    },
    {
      path: '/space-storage',
      name: 'space-storage',
      component: () => import('./views/SpaceStorage.vue'),
      meta: { title: '玛娅空间-收纳系列', keywords: '', description: '' }
    },
    {
      path: '/space-storage-more',
      name: 'space-storage-more',
      component: () => import('./views/SpaceStorageMore.vue'),
      meta: { title: '玛娅空间-收纳系列-更多', keywords: '', description: '' }
    },
    {
      path: '/cases/:page(\\d+)?',
      name: 'cases',
      component: () => import('./views/Cases.vue'),
      meta: { title: '案例', keywords: '', description: '' }
    },
    {
      path: '/shops',
      name: 'shops',
      component: () => import('./views/Shops.vue'),
      meta: { title: '玛娅展厅', keywords: '', description: '' }
    },
    {
      path: '/shop/:name(\\w+)',
      name: 'shop',
      component: () => import('./views/Shop.vue'),
      meta: { title: '玛娅展厅详情', keywords: '', description: '' }
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('./views/News.vue'),
      meta: { title: '企业动态', keywords: '', description: '' }
    },
    {
      path: '/new/:id(\\d+)',
      name: 'newsDetail',
      component: () => import('./views/New.vue'),
      meta: { title: '企业动态详情', keywords: '', description: '' }
    },
    {
      path: '/advantage',
      name: 'advantage',
      component: () => import('./views/Advantage.vue'),
      meta: { title: '品牌优势', keywords: '', description: '' }
    },
    {
      path: '/join',
      name: 'join',
      component: () => import('./views/Join.vue'),
      meta: { title: '招商加盟', keywords: '', description: '' }
    },
    {
      path: '/banquet',
      name: 'banquet',
      component: () => import('./views/Banquet.vue'),
      meta: { title: '私享家宴', keywords: '', description: '' }
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: () => import('./views/Maintenance.vue'),
      meta: { title: '清洁保养', keywords: '', description: '' }
    }
  ]
})
