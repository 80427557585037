import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import https from './https'
import { WOW } from 'wowjs'
import 'wowjs/css/libs/animate.css'
import 'fullpage-vue/src/fullpage.css'
import VueFullpage from 'fullpage-vue'
Vue.use(VueFullpage)
Vue.prototype.https = https
Vue.prototype.$wow = new WOW({ live: false })
Vue.prototype.$wowd = new WOW({ boxClass: 'wowd', live: false })
Vue.config.productionTip = false
Vue.prototype.$activeNav = 0
Vue.prototype.$shops = {
  'shxymqwqjd': {
    title: 'MAIER 上海喜盈门全屋旗舰店',
    addr: '上海市宜山路407号LG层B037A / B039 / B051B',
    tel: '15921883261（沈女士）',
    imgs: 14,
    city: '上海'
  },
  'shxymcgqjd': {
    title: 'MAIER 上海喜盈门橱柜旗舰店',
    addr: '上海市宜山路407号2层 2012 / 2016 / 2058',
    tel: '13671532891（岳女士）',
    imgs: 4,
    city: '上海'
  },
  'bjd': {
    title: 'MAIER 北京店',
    addr: '北京市朝阳区来广营新北路甲一号B座',
    tel: '15801551236（张先生）',
    imgs: 0,
    city: '北京'
  },
  'xgzmd': {
    title: 'MAIER 香港专卖店',
    addr: '香港湾仔轩尼斯道302-308号集成中心9楼903室',
    tel: '+852 90909182（Michael）',
    imgs: 0,
    city: '香港'
  },
  'ncxymd': {
    title: 'MAIER 南昌喜盈门店',
    addr: '江西省南昌市抚生路1166号喜盈门2111-2113',
    tel: '13330085715（李先生）',
    imgs: 0,
    city: '南昌'
  },
  'csxymfcqwd': {
    title: 'MAIER 长沙喜盈门范城全屋店',
    addr: '湖南省长沙市万家丽中路三段36号5楼5320',
    tel: '13671532891（岳女士）',
    imgs: 6,
    city: '长沙'
  },
  'zzhxmklzyd': {
    title: 'MAIER 郑州红星美凯龙中原店',
    addr: '河南省郑州市金水区商都路1号5楼',
    tel: '17839963189（候先生）',
    imgs: 0,
    city: '郑州'
  },
  'cqhxmkljbd': {
    title: 'MAIER 重庆红星美凯龙江北店',
    addr: '重庆市渝北区新南路888号红星美凯龙4楼',
    tel: '18696528921（李先生）',
    imgs: 0,
    city: '重庆'
  },
  'szhxmklyqd': {
    title: 'MAIER 苏州红星美凯龙园区店',
    addr: '江苏省苏州市工业园区扬清路1号5楼F8203',
    tel: '13671532891（岳女士）',
    imgs: 8,
    city: '苏州'
  },
  'hyhxmkld': {
    title: 'MAIER 衡阳红星美凯龙店',
    addr: '湖南省衡阳市雁峰区红星美凯龙二楼B8035',
    tel: '18907311981（黄先生）',
    imgs: 0,
    city: '衡阳'
  }
}

new Vue({
  router,
  store,
  vuetify,
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  },
  render: h => h(App)
}).$mount('#app')
