<template>
  <div id="bottom-navs">
    <v-row justify="center">
      <v-col cols="3" v-for="(n,i) in navs" :key="i" class="text-center">
        <a :href="n.link" :class="{ activeNav: activeNav === i}">
          <div :class="'bottom-nav-icon nav-' + n.name"></div>
          <div class="bottom-nav-title">{{ n.title }}</div>
        </a>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data: () => ({
    activeNav: null,
    navs: [
      {
        name: 'home',
        title: '首页',
        link: '/'
      },
      {
        name: 'space',
        title: '玛娅空间',
        link: '/spaces'
      },
      {
        name: 'shop',
        title: '玛娅展厅',
        link: '/shops'
      },
      {
        name: 'case',
        title: '项目案例',
        link: '/cases'
      }
    ]
  }),
  created () {
  },
  mounted: function () {
  },
  watch: {
    '$store.state.activeNav': {
      handler: function (val, oldVal) {
        this.activeNav = val
      },
      deep: true
    }
  },
  methods: {
    backToTop () {
      document.querySelector('.ctk-top-bar').scrollIntoView(true)
    }
  }
}
</script>
