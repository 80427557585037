import Vue from 'vue'
import Vuex from 'vuex'
import https from './https'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeNav: null,
    companyInfo: {}
  },
  actions: {
    loadInfo () {
      https.get('info').then(response => {
        if (response.code === 0) {
          this.commit('updateInfo', response.data)
        }
      })
    }
  },
  mutations: {
    updateInfo (state, companyData) {
      state.companyInfo = companyData
    },
    updateActiveNav (state, activeNav) {
      state.activeNav = activeNav
    }
  },
  getters: {
    companyInfo (state) {
      return state.companyInfo
    }
  }
})
